.matx-search-box {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 9;
  .search-box {
    outline: none;
    border: 2px solid #cacaca;
    border-radius: 20px;
    font-size: 1rem;
    height: calc(80% - 5px);
  }
}

.matx-search-icon {
  margin-top: -45px;
}
