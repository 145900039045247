.sidenav,
.topbar,
.content-wrap {
  transition: all 0.3s ease;
}

.card-login {
  background-color: #c2c2c2;
}

.signup {
  background: #1a2038;
  .signup-card {
    max-width: 800px;
    border-radius: 12px !important;
    img {
      width: 200px;
    }
  }
}

.card-login-image-background {
  background-color: #f3f2f2;
}

.card-login-background {
  background-color: #1a2038;
}

.titleWithSearchBox {
  width: 90%;
  float: left;
}

.calendarStartDate {
  width: 40%;
  float: left;
}

.calendarEndDate {
  width: 40%;
  float: left;
}

.labelCalendarDistance {
  margin-right: 10px;
}

.buttonSendDate {
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: #28a745;
  border-color: #28a745;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.main_search {
  display: flex;
  line-height: 50px;
  margin: 0 50px 0 0;
}

.main_search_input {
  min-height: auto;
  padding: 0.33em 0.75em;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 0;
  background: transparent;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #4f4f4f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin: 0;
  font-family: inherit;
}

.search_button {
  margin-left: -1px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-top: 0.59rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
  color: #fff;
  background-color: #1266f1;
  text-transform: uppercase;
  vertical-align: bottom;
  border: 0;
  box-shadow:
    0 2px 5px 0 rgb(0 0 0 / 20%),
    0 2px 10px 0 rgb(0 0 0 / 10%);
  font-weight: 500;
  padding: 0.625rem 1.5rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  user-select: none;
  margin: 0;
  font-family: inherit;
  text-rendering: auto;
  word-spacing: normal;
}

input,
textarea,
button {
  margin: 0;

  &:disabled {
    background-color: #cccccc;
    color: #666666;
  }
}

.buttonFilter {
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 0;
  width: 200px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.sidenav .scrollable {
  width: $sidenav-width;
}

.card-account-register-field {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 85%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #ececec;
  margin-bottom: 60px;
  margin-top: 30px;
  border-radius: 5px;
}

.card-account-register-field:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-payee-on-payout-page {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 97%;
  height: auto;
  margin-left: 1.3%;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #b0fc7e;
  margin-bottom: 60px;
  margin-top: 30px;
  border-radius: 5px;
}

.white-table-border-radius {
  padding-right: 2%;
  padding-left: 2%;
  padding-top: 1%;
  padding-bottom: 0.1%;
  color: white;
  background-color: white;
  border-radius: 1.5rem;
}

.select {
  background-color: white;
}
.select-register-input {
  width: 800px;
  height: auto;
}

.column-table {
  width: 270px;
}

.account-register-input-text {
  display: block;
  width: 800px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.account-register-input-text-area {
  display: block;
  width: 800px;
  height: 120px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.input-inside-card {
  width: 800px;
  height: auto;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.center-radio {
  margin: auto;
  padding-left: 300px;
}

.center-select {
  height: 130px;
  width: 50%;
  margin: 10px 375px;
}

.fab-icon {
  margin-left: 23%;
  margin-top: 7%;
}

.fab-main-btn {
  background-color: #17a2b8;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #17a2b8;
  border: none;
  outline: none;
  color: #fff;
  font-size: 36px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  transition: 0.3s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: absolute;
  right: 30px;
  top: auto;
}

.center-account-type {
  height: 60px;
  width: 50%;
  margin: 10px 375px;
}

input[type='radio'] {
  margin: auto;
  width: 15px;
  height: 15px;
}

.row {
  content: '';
  width: 100%;
  display: table;
  clear: both;
}

.card-modal-report {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #ececec;
  margin-bottom: 60px;
  margin-top: 30px;
  border-radius: 5px;
}

.card-modal-report:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-modal-line {
}

.account-input-radio {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 35px;
  font-size: 3rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
  position: relative;
}

.btn-submit {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  width: 210px;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin: auto;
  margin-left: 43%;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.card-title-position {
  margin-left: 85px;
  float: left;
}

.card-btn-register {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  width: 250px;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin: auto;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.receivable-card {
  margin-top: 30px;
}

.card-btn-remove {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  float: right;
  margin-right: 10px;
  margin-top: -10px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.layout1 {
  height: 100%;
  .content-wrap {
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    height: 100%;
    .topbar {
      top: 0;
      z-index: 96;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.95) 44%,
        rgba(247, 247, 247, 0.4) 50%,
        rgba(255, 255, 255, 0)
      );
      .topbar-hold {
        position: relative;
        // box-shadow: $elevation-z8;
        height: $topbar-height;
        border-radius: inherit;
        padding: 0 1rem;
        // z-index: 15;
      }
    }
    .scrollable-content {
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      .content {
        position: relative;
      }
    }
  }
  // Fixed Topbar
  // sidenav full
  &.sidenav-full {
    .sidenav {
      // width: $sidenav-width;
      left: 0;
      .sidenav__toggle {
        // display: block;
      }
    }
    .content-wrap {
      width: calc(100% - #{$sidenav-width});
      vertical-align: top;
      margin-left: $sidenav-width;
    }
  }
  // sidenav compact
  &.sidenav-compact {
    .sidenav {
      width: $sidenav-compact-width;
      overflow: hidden;
      .brand-area {
        padding: 13px 18px 13px 28px;
        .brand__text {
          display: none;
        }
      }
      .scrollable {
        padding-right: 6px;
        padding-left: 10px;
        .sidenav__user {
          padding: 0.5rem 18px;
          background: none;
          .username-photo {
            align-items: flex-start;
          }
          img {
            height: 1.8rem;
            width: 1.8rem;
          }
          .username,
          .user__menu {
            display: none;
          }
        }
        .navigation {
          .nav-item {
            padding: 0;
            width: 48px;
            overflow: hidden;
            justify-content: center;
            margin-left: 6px;
            // border-radius: 2px;
            .item-text,
            .item-arrow,
            .badge {
              display: none;
            }
            > button {
              justify-content: center;
            }
            .icon-text {
              margin-left: 4px;
              font-size: 10px;
              text-indent: 0;
              // height: 16px;
              // width: 16px;
              // line-height: 16px;
              &::after {
                display: none;
              }
            }
          }
        }
      }
      // COMPACT HOVER
      &:hover {
        width: $sidenav-width;
        .brand-area {
          padding: 13px 18px 13px 24px;
          .brand__text {
            display: inline-block;
          }
        }
        .scrollable {
          width: $sidenav-width;
          padding-left: 20px;
          padding-right: 20px;
          .sidenav__user {
            padding: 0.5rem 12px;
            background-color: rgba(0, 0, 0, 0.05);
            .username-photo {
              align-items: center;
            }
            img {
              height: 2.5rem;
              width: 2.5rem;
            }
            .username,
            .user__menu {
              display: flex;
            }
          }
          .navigation {
            button.nav-item {
              padding: 0 16px;
            }
            .nav-item {
              // padding: 0 20px;
              width: 100%;
              justify-content: space-between;
              margin-left: 0;
              .item-text,
              .item-arrow,
              .badge {
                display: inline-block;
              }
              > button {
                justify-content: flex-start;
              }
              .icon-text {
                margin-left: 0;
                font-size: 10px;
                text-indent: -10000px;
                // height: 16px;
                // width: 16px;
                // line-height: 16px;
                &::after {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .content-wrap {
      width: calc(100% - #{$sidenav-compact-width});
      vertical-align: top;
      margin-left: $sidenav-compact-width;
    }
  }
  // closed sidenav
  &.sidenav-close {
    .sidenav {
      // width: 0px;
      left: -#{$sidenav-width};
    }
    .content-wrap {
      width: 100%;
      vertical-align: top;
      margin-left: 0px;
      .topbar {
        width: 100%;
      }
      .content {
      }
    }
  }
  // mobile sidenav
  &.sidenav-mobile {
    .sidenav {
      width: $sidenav-width;
    }
    .content-wrap {
      width: 100%;
      vertical-align: top;
      margin-left: 0px;
      .topbar {
        width: 100%;
      }
    }
  }
}
